export const url = 'https://check.edu.gov.kg/api.php';
export const heatmap_url = 'https://geoserver.24mycrm.com/monmap/';

export const instituion_sectors = [
    {
        id: '1',
        name: 'Мамлекеттик менчик'
    },
    {
        id: '2',
        name: 'Жеке менчик'
    },
    {
        id: '3',
        name: 'Муниципалдык менчик'
    },
    {
        id: '6',
        name: 'Менчиктин башка формасы'
    },
]

export const institution_types = [
    {
        id: '1',
        name: 'Мектепке чейинки билим берүү уюму'
    },
    {
        id: '2',
        name: 'Жалпы билим берүү'
    },
    {
        id: '3',
        name: 'Баштапкы кесиптик билим берүү уюму'
    },
    {
        id: '4',
        name: 'Орто кесиптик билим берүү уюму'
    },
    {
        id: '5',
        name: 'Кесиптик билим берүү уюму'
    },
    {
        id: '6',
        name: 'Жогорку кесиптик билим берүү уюму'
    },
    {
        id: '7',
        name: 'Кошумча билим берүү уюму'
    },
    {
        id: '8',
        name: 'Кошумча кесиптик билим берүү уюму'
    },
    {
        id: '9',
        name: 'Билим берүүнү башкаруу органы'
    },
    {
        id: '18',
        name: 'Мектептен тышкары билим берүү уюму'
    },
    {
        id: '19',
        name: 'Тест'
    },
    {
        id: '20',
        name: 'ВПО при ВПО'
    },
    {
        id: '21',
        name: 'СПО при ВПО'
    },
    {
        id: '23',
        name: 'Мектеп карамагындагы уюм'
    },
]

export const institution_view = [
    {
        id: '1',
        name: '6 айдан 3 жашка чейинки балдар үчүн ясли'
    },
    {
        id: '2',
        name: 'Ар түрдүү сабактар тереңдетилип окутулган мектеп'
    },
    {
        id: '3',
        name: 'Гимназия'
    },
    {
        id: '4',
        name: 'Лицей'
    },
    {
        id: '5',
        name: 'Мектеп-комплекс'
    },
    {
        id: '7',
        name: 'Мектеп-интернат'
    },
    {
        id: '8',
        name: 'Мектепке тиешелүү балдар үйү'
    },
    {
        id: '9',
        name: 'Ден соолугунун мүмкүнчүлүгү чектелген (ДМЧ) балдар үчүн мектеп же мектеп-интернат'
    },
    {
        id: '10',
        name: 'ДМЧ балдар үчүн класстары бар мектеп'
    },
    {
        id: '11',
        name: 'ДМЧ балдарды инклюзивдик окутуу мектеби'
    },
    {
        id: '12',
        name: 'Тарбиянын атайын шарттарына муктаж болгон балдар жана өспүрүмдөр үчүн атайын мектеп'
    },
    {
        id: '13',
        name: 'Гимназия-интернат'
    },
    {
        id: '14',
        name: 'Лицей-интернат'
    },
    {
        id: '15',
        name: 'ДМЧ балдар үчүн мектеп-интернаты'
    },
    {
        id: '16',
        name: 'Сабактар тереңдетилип окутулган класстары бар мектеп-интернат'
    },
    {
        id: '17',
        name: 'Санатордук-токой мектеп-интернат'
    },
    {
        id: '21',
        name: '6 айдан 7 жашка чейинки балдар үчүн ясли-бала бакча'
    },
    {
        id: '22',
        name: '3 жаштан 7 жашка чейинки балдар үчүн бала бакча'
    },
    {
        id: '23',
        name: 'Өзгөчө муктаждыгы бар балдар үчүн атайын бала бакча'
    },
    {
        id: '24',
        name: 'Жалпыланган бала бакча, ар түрдүү мөөнөткө кабыл алуусу менен(толук толук эмес күн)'
    },
    {
        id: '25',
        name: 'ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА'
    },
    {
        id: '26',
        name: 'СРЕДНЯЯ ШКОЛА'
    },
    {
        id: '18',
        name: 'Тарбиянын өзгөчө шарттарына муктаж болгон балдар жана өспүрүмдөр үчүн атайын мектеп-интернат'
    },
    {
        id: '20',
        name: 'ДМЧ балдарды инклюзивдик окутууну тереңдетип үйрөтчү класстары бар мектеп'
    },
    {
        id: '52',
        name: 'Сүйлөө речи катуу бузулган балдар үчүн мектептер'
    },
    {
        id: '53',
        name: 'Санаторий-токой мектептери'
    },
    {
        id: '54',
        name: 'Ар кандай оорулары бар балдар үчүн санаторий мектеп-интернаттары'
    },
    {
        id: '55',
        name: 'Оор тарбиялануучу балдар үчүн мектептер'
    },
    {
        id: '56',
        name: 'Узартылган күн топтору бар мектептер'
    },
    {
        id: '57',
        name: 'Мектепке тиешелүү балдар үйү'
    },
    {
        id: '58',
        name: 'Ведомстволук'
    },
    {
        id: '27',
        name: 'Негизги мектеп'
    },
    {
        id: '29',
        name: 'Башталгыч мектеп'
    },
    {
        id: '30',
        name: 'Колледж'
    },
    {
        id: '31',
        name: 'Институт'
    },
    {
        id: '32',
        name: 'Академия'
    },
    {
        id: '34',
        name: 'Кечки жалпы билим берүү мектеби'
    },
    {
        id: '35',
        name: 'Күндүзгү кесиптик окуу жайлар (мектептер)'
    },
    {
        id: '36',
        name: 'Атайын кесиптик окуу жайлары (колледждер)'
    },
    {
        id: '37',
        name: 'Күндүзгү адистештирилген бөлүмдөр сс'
    },
    {
        id: '38',
        name: 'lss базасында күндүзгү профессионалдык бөлүмдөр'
    },
    {
        id: '41',
        name: 'Угуусунан ажыраган жана угуусунан жабыркагандар үчүн'
    },
    {
        id: '42',
        name: 'Көзү азиздер жана начар көрүүчүлөр үчүн'
    },
    {
        id: '43',
        name: 'Университет'
    },
    {
        id: '45',
        name: 'Кесиптик лицейлер'
    },
    {
        id: '46',
        name: 'Акыл эси артта калган балдар үчүн мектептер (көмөкчү)'
    },
    {
        id: '47',
        name: 'Акыл-эси артта калган жетим балдар үчүн мектеп'
    },
    {
        id: '48',
        name: 'Көзү азиздер мектеби'
    },
    {
        id: '49',
        name: 'Көрүүсү начарлар үчүн мектептер'
    },
    {
        id: '50',
        name: 'Угуусунан ажыраган балдар үчүн мектеп'
    },
    {
        id: '51',
        name: 'Угуусунан ажыраган жана угуусунан жабыркагандар үчүн'
    },
]